import { AxiosRequestConfig } from "axios";
import { hasPermission } from "components/permissionsGate";
import { ClientRequestsModel } from "models/clientRequests";
import { TicketModel } from "models/tickets";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class ClientRequestsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }
  public async Fetch(
    page: number,
    StatusFliter: any,
    StatusPriority: any,
    DateRange: any,
    searchBy: any,
    search: any,
    isActive: any,
    sort: any,
    companyPlace: any,
    myTickets: number,
    filterDateBy: string
  ): Promise<ApiResponse<ClientRequestsModel[]>> {
    return this.get(
      `/api/client-requests?page=${page}&items_per_page=8${
        StatusFliter !== "0" ? "&filter[status]=" + StatusFliter : ""
      }${StatusPriority !== "-1" ? "&filter[priority]=" + StatusPriority : ""}${
        DateRange !== "" && DateRange !== null
          ? `&filter[${filterDateBy}]=` + DateRange
          : ""
      }${search !== "" ? "&filter[" + searchBy + "]=" + search : ""}${
        isActive === 1 ? "&filter[active]=" + isActive : ""
      }${myTickets !== 0 ? "&filter[my_tickets]=" + myTickets : ""}${
        sort !== "" ? "&sort=" + sort : ""
      }${
        companyPlace !== "0" ? "&filter[company_place_id]=" + companyPlace : ""
      }`
    );
  }
  public async FetchSingle(
    id: any,
    page: number,
    StatusFliter: any,
    StatusPriority: any,
    DateRange: any,
    searchBy: any,
    search: any,
    isActive: any,
    sort: any,
    companyPlace: any,
    myTickets: number
  ): Promise<ApiResponse<TicketModel[]>> {
    return this.get(
      `/api/tickets/${id}/client-request?page=${page}&items_per_page=8${
        StatusFliter !== "0" ? "&filter[status_id]=" + StatusFliter : ""
      }${StatusPriority !== "-1" ? "&filter[priority]=" + StatusPriority : ""}${
        DateRange !== "" && DateRange !== null
          ? "&filter[created_at]=" + DateRange
          : ""
      }${search !== "" ? "&filter[" + searchBy + "]=" + search : ""}${
        isActive === 1 ? "&filter[active]=" + isActive : ""
      }${myTickets !== 0 ? "&filter[my_tickets]=" + myTickets : ""}${
        sort !== "" ? "&sort=" + sort : ""
      }${
        companyPlace !== "0" ? "&filter[company_place_id]=" + companyPlace : ""
      }`
    );
  }

  public async fetchSingleWithId(
    id: number
  ): Promise<ApiResponse<TicketModel[]>> {
    return this.get(`/api/tickets/${id}/client-request`);
  }

  public async PostSingle(
    data: any
  ): Promise<ApiResponse<ClientRequestsModel>> {
    return this.post(`/api/tickets/`, data);
  }

  public async fetchByUsers(
    userId: number,
    page: number,
    search: string
  ): Promise<ApiResponse<ClientRequestsModel[]>> {
    return this.get(
      `/api/users/client-request?page=${page}&items_per_page=5&filter[requested_by]=${userId}${
        search ? "&filter[id]=" + search : ""
      }`
    );
  }
}

const clientRequestService = new ClientRequestsService();

export const FetchClientRequests = async (
  page: number,
  StatusFliter: any,
  StatusPriority: any,
  DateRange: any,
  searchBy: any,
  search: any,
  isActive: any,
  sort: any,
  companyPlace: any,
  myTickets: number,
  filterDateBy: string
) => {
  if (
    hasPermission(["browse_tickets"]) ||
    hasPermission(["browse_ticket_pending"]) ||
    hasPermission(["browse_ticket_active"]) ||
    hasPermission(["browse_ticket_done"]) ||
    hasPermission(["browse_ticket_approved"]) ||
    hasPermission(["browse_ticket_denied"])
  ) {
    const result: any = await clientRequestService.Fetch(
      page,
      StatusFliter,
      StatusPriority,
      DateRange,
      searchBy,
      search,
      isActive,
      sort,
      companyPlace,
      myTickets,
      filterDateBy
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const FetchClientRequest = async (
  id: any,
  page: number,
  StatusFliter: any,
  StatusPriority: any,
  DateRange: any,
  searchBy: any,
  search: any,
  isActive: any,
  sort: any,
  companyPlace: any,
  myTickets: number
) => {
  if (
    hasPermission(["browse_tickets"]) ||
    hasPermission(["browse_ticket_pending"]) ||
    hasPermission(["browse_ticket_active"]) ||
    hasPermission(["browse_ticket_done"]) ||
    hasPermission(["browse_ticket_approved"]) ||
    hasPermission(["browse_ticket_denied"])
  ) {
    if (id !== "0" && !isNaN(id) && hasPermission(["read_tickets"])) {
      const result: any = await clientRequestService.FetchSingle(
        id,
        page,
        StatusFliter,
        StatusPriority,
        DateRange,
        searchBy,
        search,
        isActive,
        sort,
        companyPlace,
        myTickets
      );
      if (isError(result)) {
        ApiErrorNotification(result);
        throw result;
      } else {
        return result;
      }
    }
  }
};

export const PostClientRequest = async (data: any) => {
  const result: any = await clientRequestService.PostSingle(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchClientRequestSingle = async (id: any) => {
  if (
    hasPermission(["browse_tickets"]) ||
    hasPermission(["browse_ticket_pending"]) ||
    hasPermission(["browse_ticket_active"]) ||
    hasPermission(["browse_ticket_done"]) ||
    hasPermission(["browse_ticket_approved"]) ||
    hasPermission(["browse_ticket_denied"])
  ) {
    if (id !== "0" && hasPermission(["read_tickets"])) {
      const result: any = await clientRequestService.fetchSingleWithId(id);
      if (isError(result)) {
        ApiErrorNotification(result);
        throw result;
      } else {
        return result;
      }
    }
  }
};

export const FetchUsersClientRequests = async (
  userId: number,
  page: number,
  search: string
) => {
  const result: any = await clientRequestService.fetchByUsers(
    userId,
    page,
    search
  );
  if (isError(result)) {
    ApiErrorNotification(result);
  } else {
    return result;
  }
};
