import { useQuery } from "react-query";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";
import { FetchClientRequest } from "services/clientRequests";
import { ClientRequestModel } from "models/clientRequests";
import useDebounce from "hooks/others/useDebounce";

export default function useClientRequest(
  id: number,
  page: number,
  StatusFliter: any,
  StatusPriority: any,
  DateRange: any,
  SearchBy: any,
  SearchText: any,
  isActive: any,
  sort: any,
  copmanyPlace: any,
  myTickets: number,
  enabled?: boolean
) {
  const { newParam: searchParam } = useDebounce(SearchText);

  const queryKey = [
    "clientRequest",
    id,
    page,
    StatusFliter,
    StatusPriority,
    DateRange,
    SearchBy,
    searchParam,
    isActive,
    sort,
    copmanyPlace,
    myTickets,
    enabled,
  ];

  return {
    ...useQuery<ApiResult<ClientRequestModel[]>, ApiError>(
      queryKey,
      () =>
        FetchClientRequest(
          id,
          page,
          StatusFliter,
          StatusPriority,
          DateRange,
          SearchBy,
          SearchText,
          isActive,
          sort,
          copmanyPlace,
          myTickets
        ),
      {
        staleTime: 30000,
        enabled: enabled,
        refetchOnWindowFocus: false,
      }
    ),
  };
}
