import { Select, Input, Radio } from "antd";
import React from "react";
import { useWidth } from "utils/helpers/use-is-mobile";

const { Option } = Select;
const { Search } = Input;

interface option {
  name: string;
  value: string;
}

interface elementConfig {
  onChange: (e: any) => void;
  value?: string;
  suffixIcon?: React.ReactNode;
  placeholder?: string;
  allowClear?: boolean;
  defaultValue?: string;
}

export interface TableFiltersProps {
  elementType: string;
  elementConfig: elementConfig;
  label: string;
  options?: option[];
}

const TableFilters = (props: TableFiltersProps) => {
  const { isMobile } = useWidth();

  let element = <></>;

  const divStyle = {
    flexBasis: isMobile ? "45%" : "",
  };

  switch (props.elementType) {
    case "select":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Select
            {...props.elementConfig}
            style={{ width: isMobile ? "100%" : "170px" }}
          >
            {props.options?.map((option) => (
              <Option key={option.value}>{option.name}</Option>
            ))}
          </Select>
        </div>
      );
      break;
    case "search":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Search
            style={{ width: isMobile ? "100%" : "270px" }}
            {...props.elementConfig}
          />
        </div>
      );
      break;
    case "radio":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Radio.Group
            style={{ width: isMobile ? "100%" : "" }}
            {...props.elementConfig}
          >
            {props.options?.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      );
  }
  return element;
};

export default TableFilters;
